import React, { PropsWithChildren, useContext } from "react";
import { Dialog } from "@mui/material";
import { layerSectionMixin, mapPageMixin, sidebarHeaderMixin, styled } from "@iventis/styles";
import { ErrorBoundaryContext, IventisFallbackProps, LargeFallback } from "@iventis/error-boundaries";
import { errorToErrorCode } from "@iventis/error-boundaries/src/error-code";
import { SentryErrorBoundary, SentryFallbackRender } from "@iventis/observability-and-monitoring/src/error-boundary/sentry-error-boundary";

const MapFallbackSkeleton: React.FC<IventisFallbackProps> = ({ error, resetErrorBoundary }) => (
    <MainContainer isMediumScreenSize={false} leftWidth="400px">
        <Sidebar>
            <SidebarHeader />
            <SidebarContent>
                <LayersHeader />
                <LayersContainer />
                <LayersToolbar />
            </SidebarContent>
        </Sidebar>
        <Map />

        <Toolbar />
        <Dialog open>
            <StyledLargeFallback error={error} action="reload" resetErrorBoundary={resetErrorBoundary} />
        </Dialog>
    </MainContainer>
);

export const MapSkeletonErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
    const errorContext = useContext(ErrorBoundaryContext);

    const onError: SentryFallbackRender = ({ error, resetError }) => {
        errorContext.onError(errorToErrorCode(error as Error), error as Error);
        return <MapFallbackSkeleton error={error} resetErrorBoundary={resetError} />;
    };

    return <SentryErrorBoundary fallback={onError}>{children}</SentryErrorBoundary>;
};

const StyledLargeFallback = styled(LargeFallback)`
    background-color: #fff;
`;

const MainContainer = styled.div<{ leftWidth: string; isMediumScreenSize: boolean }>`
    ${mapPageMixin}
`;

const Sidebar = styled.div`
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;

const SidebarHeader = styled.div`
    background-color: #484848;
    ${sidebarHeaderMixin};
`;

const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
`;

const LayersHeader = styled.div`
    ${layerSectionMixin}
    border-bottom: 1px solid #eeeeee;
`;

const LayersContainer = styled.div`
    height: 100%;
`;

const LayersToolbar = styled.div`
    ${layerSectionMixin}
    background-color: #d9d9d9;
`;

const Map = styled.div`
    height: 100%;
    background-color: #979797;
`;

const Toolbar = styled.div`
    height: 100%;
    background-color: #484848;
`;
