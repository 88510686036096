import React, { PropsWithChildren, useContext } from "react";
import { styled } from "@iventis/styles";
import { Dialog } from "@mui/material";
import { ErrorBoundaryContext, IventisFallbackProps, LargeFallback } from "@iventis/error-boundaries";
import { errorToErrorCode } from "@iventis/error-boundaries/src/error-code";
import { SentryErrorBoundary, SentryFallbackRender } from "@iventis/observability-and-monitoring/src/error-boundary/sentry-error-boundary";

const DashboardFallbackSkeleton: React.FC<IventisFallbackProps> = ({ error, resetErrorBoundary }) => (
    <MainContainer>
        <DashboardImage />
        <DashboardContent>
            <DashboardSection />
            <DashboardSection />
        </DashboardContent>
        <Dialog open>
            <StyledLargeFallback error={error} action="reload" resetErrorBoundary={resetErrorBoundary} />
        </Dialog>
    </MainContainer>
);

export const DashboardSkeletonErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
    const errorContext = useContext(ErrorBoundaryContext);

    const onError: SentryFallbackRender = ({ error, resetError }) => {
        errorContext.onError(errorToErrorCode(error as Error), error as Error);
        return <DashboardFallbackSkeleton error={error} resetErrorBoundary={resetError} />;
    };

    return <SentryErrorBoundary fallback={onError}>{children}</SentryErrorBoundary>;
};

const DashboardSection = styled.div`
    margin: 20px;
    background-color: #d9d9d9;
`;

const StyledLargeFallback = styled(LargeFallback)`
    background-color: #fff;
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const DashboardImage = styled.div`
    flex: 2 1;
    position: relative;
    overflow: hidden;
    background-color: #757575;
`;

const DashboardContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    -webkit-box-flex: 1;
    flex-grow: 1;
    background-color: #fff;
`;
