/* eslint no-console: ["error", { allow: ["error"] }] */
/* eslint-disable no-param-reassign */
import { Content } from "@iventis/translations/content/typed-content";
import {
    MapModuleLayer,
    MapboxglStyle,
    StoredPosition,
    MappingEngine,
    StoredBounds,
    Source,
    SelectedMapComment,
    ModelData,
    DrawingModifier,
    MapState,
} from "@iventis/map-engine/src/types/store-schema";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { CompositionMapObject } from "@iventis/map-engine/src/types/internal";
import { MicroService } from "@iventis/api-helpers";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { Map } from "@iventis/domain-model/model/map";
import { DataField } from "@iventis/domain-model/model/dataField";
import { SavedMapView } from "@iventis/domain-model/model/savedMapView";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { Asset } from "@iventis/domain-model/model/asset";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { MapMode } from "@iventis/map-engine/src/machines/map-machines.types";
import { polygon, Polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import { MapEventTypes } from "@iventis/map-engine/src/types/map-stream.types";
import { getExpiryAsISOString, parseTimeDateToNumber, parseDateToEpochSeconds } from "@iventis/utilities";
import { setLocalGeoJson } from "@iventis/map-engine/src/utilities/geojson-helpers";
import { SitemapStyle } from "@iventis/map-engine/src/types/sitemap-style";
import { toast } from "@iventis/toasts/src/toast";
import { MapSitemapConfig } from "@iventis/domain-model/model/mapSitemapConfig";
import { Level, DomainLayer, MapGlobalState, SavedMapViewStages, CreateMapRequestPayload } from "@iventis/map-engine/src/state/map.state.types";
import { WaitingForEvent, PayloadActionRequests, SocketEvent, LoadingEvent } from "@iventis/types/loading.types";
import { initialState } from "@iventis/map-engine/src/state/map-state.initial-values";
import { mapObjectUpdateToCompositionMapObject } from "@iventis/map-engine/src/utilities/converters";
import content from "@iventis/translations/content";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { checkModeIsPresent } from "@iventis/map-engine/src/machines/mode-machine.helpers";
import { translate } from "@iventis/translations/translation";
import { removeWaitingForByEventName, removeWaitingForById } from "@iventis/api/src/state-helpers";
import { analysisLineLayerId, analysisPolygonLayerId, commentsLayerId } from "@iventis/map-engine";
import { getApiBaseUrl } from "@iventis/api/src/api";
import { CommentsDrawingModes } from "@iventis/map-engine/src/utilities/comments-drawing";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { getMapObjectNameSystemDatafield } from "@iventis/datafields";
import { AnalysisType } from "@iventis/domain-model/model/analysisType";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { SelectedMapObject, LocalGeoJsonObject, LocalGeoJson } from "@iventis/map-types";
import { DigitalTwinInstance } from "@iventis/domain-model/model/digitalTwinInstance";
import { isAnalysisLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import isEqual from "lodash.isequal";
import { mapDataTableEventStream, MapDataTableEvent } from "../data-table/sidebar-data-table-event-stream";
import {
    composeNewObjects,
    set3DTerrian,
    createNewLocalId,
    parseLayerToIventisLayer,
    setIdPair,
    setMap,
    setMapView,
    setMode,
    stampValue,
    updateLayerSelectedValue,
    updateLayerStyle,
    updateMapLayerWithStamp,
    setLocalToRemoteMapObjectIds,
    removeObjects,
    setGlobe,
    updateAnalysisObjectsSelectionProperty,
    patchLocalMapObjects,
    addMetaDataAndTagsToMapBackgroundStyle,
    patchImageAttributes,
    updateMapStateWithFilterChange,
    updateDigitalTwinInstance,
    isAnalysisToolSelectionValid,
} from "./map.slice.functions";
import { mergeGeojson } from "./map.slice.get-map-functions";
import {
    createLayerRequest,
    createMapRequest,
    createObjects,
    createSavedMapView,
    deleteObjects,
    downloadSitemaps,
    getBasemap,
    getBackgroundMaps,
    getGeoJson,
    getProjectDataFields,
    getSitemaps,
    patchDefaultSavedMapView,
    updateLayer,
    patchMapLayer,
    updateSavedMapView,
    updateSitemapSignatures,
    updateMap,
    getRemoteLayerGeometry,
    restoreObjects,
    patchMapLayers,
    createLayerDataFieldThunk,
    updateLayerDataFieldThunk,
    deleteLayerDataFieldThunk,
    deleteObjectsByLayerIds,
    uploadGeometry,
    patchMapComment,
    getMapCommentFeature,
    patchMapObjectsDataFieldValues,
    GetGeoJsonResponse,
} from "./map.slice.thunks";
import { eventStream } from "../event-stream";
import { SidebarOrderUpdate } from "../sidebar-order-helpers";
import { getMap, getMapModels, getMapObjectAndCommentBounds, getMapObjectsForMap } from "./map.slice.get-map-thunks";
import { MapObjectUpdateDataField } from "./map.slice.types";
import { mapUndoRedoEventStream } from "../map-undo-redo-stream";

export const baseURL = getApiBaseUrl(MicroService.MAPPING);

// Returns an array of available levels, based on the sitemaps which are selected
export const getAvailableLevels = (sitemaps: Sitemap[], sitemapConfigs: MapSitemapConfig[]): Level[] => {
    const levels: Level[] = [];
    const visibleSitemaps = sitemapConfigs.filter((sc) => sc.visible !== false);
    visibleSitemaps.forEach((vs) => {
        const sitemap = sitemaps.find((s) => s.id === vs.sitemapId);
        if (sitemap != null) {
            const version = sitemap.versions.find((v) => v.id === vs.versionId);
            if (version != null) {
                version.sitemapVersionLevels.forEach((level) => {
                    const existingLevel = levels.find((l) => l.index === level.levelIndex);
                    if (existingLevel == null) {
                        // Create a new level for that index
                        levels.push({
                            index: level.levelIndex,
                            abbreviation: level.levelAbbreviation,
                            name: level.levelName,
                        });
                    } else {
                        // Append names to existing level if they differ
                        if (!existingLevel.abbreviation.includes(level.levelAbbreviation)) {
                            existingLevel.abbreviation = `${existingLevel.abbreviation} / ${level.levelAbbreviation}`;
                        }
                        if (!existingLevel.name.includes(level.levelName)) {
                            existingLevel.name = `${existingLevel.name} / ${level.levelName}`;
                        }
                    }
                });
            }
        }
    });
    if (levels.length === 0) {
        return [
            {
                name: "Group Floor",
                abbreviation: "GF",
                index: 0,
            },
        ];
    }
    return levels;
};

// Selects an appropriate new level based on the previously selected ones and those available
const getNewLevel = (levels: Level[], previousLevelIndex: number): number => {
    if (levels.some((l) => l.index === previousLevelIndex)) {
        return previousLevelIndex;
    }
    return 0;
};

// Returns a list of sitemaps which are currnetly in view
export const getSitemapsInView = (bounds: number[][][], sitemaps: Sitemap[]) => {
    const viewPolygon = polygon(bounds);
    let sitemapsInView: Sitemap[] = JSON.parse(JSON.stringify(sitemaps));
    sitemapsInView.forEach((sitemap) => {
        sitemap.versions.forEach((version) => {
            // Filter levels by those which intersect with the view
            version.sitemapVersionLevels = version.sitemapVersionLevels.filter((level) => {
                const levelPolygon = polygon(level.bounding.coordinates);
                const intersection = intersect(viewPolygon, levelPolygon);
                return intersection;
            });
        });
        // Filter versions by those which have levels which intersect with the view
        sitemap.versions = sitemap.versions.filter((v) => v.sitemapVersionLevels.length > 0);
    });
    // Filter sitemaps by those which have versions which intersect with the view
    sitemapsInView = sitemapsInView.filter((s) => s.versions.length > 0);
    return sitemapsInView;
};

const patchStateLayer = (layer: OptionalExceptFor<MapLayer | DomainLayer, "id">, state: MapGlobalState) => {
    const index = state.mapModule.layers.value.findIndex((lyr) => lyr.id === layer.id);
    const layers = state.mapModule.layers.value;
    Object.keys(layer).forEach((key) => {
        layers[index][key] = layer[key];
    });
    // Update the layer's stamp to tell the engine to re-render this layer
    layers[index] = { ...parseLayerToIventisLayer(layers[index]), stamp: uuid() };
    stampValue(state.mapModule.layers, layers);
};

export const map = createSlice({
    initialState,
    name: "Map",
    reducers: {
        setSavedViewMapWizard(state: MapGlobalState, action: PayloadAction<{ stage: SavedMapViewStages; id: string }>) {
            state.savedMapViewWizard.stage = action.payload.stage;
            state.savedMapViewWizard.mapViewId = action.payload.id;
        },
        setMode(state: MapGlobalState, action: PayloadAction<MapMode>) {
            setMode(state.mapModule, action.payload);
            if (checkModeIsPresent(action.payload, "read")) {
                state.showRoutePlanner = false;
            }
        },
        setIsPlacingGeometry(state: MapGlobalState, action: PayloadAction<boolean>) {
            state.isPlacingGeometry = action.payload;
        },
        // Pass in a string of map object ids that you want selected. Overwrites current state.
        setMapObjectsSelected(state: MapGlobalState, action: PayloadAction<{ newSelection: SelectedMapObject[]; addToUndoStack: boolean }>) {
            // Before we update the state, check if the selection has changed and if so, push to the undo stack
            const oldSelection: SelectedMapObject[] = JSON.parse(JSON.stringify(state.mapModule.mapObjectsSelected.value));
            if (
                action.payload.addToUndoStack &&
                !isEqual(
                    oldSelection.map(({ objectId }) => objectId),
                    action.payload.newSelection.map(({ objectId }) => objectId)
                )
            ) {
                mapUndoRedoEventStream.next({
                    type: "mapObjectSelection",
                    payload: { oldSelection, newSelection: action.payload.newSelection },
                });
            }
            if ([...action.payload.newSelection, ...state.mapModule.mapObjectsSelected.value].some(({ layerId }) => isAnalysisLayer(layerId))) {
                updateAnalysisObjectsSelectionProperty(state.mapModule, action.payload.newSelection);
                if (action.payload.newSelection.length === 1) {
                    // Check if the correct analysis tool is selected based on the analysis object
                    const { layerId } = action.payload.newSelection[0];
                    const isValid = isAnalysisToolSelectionValid(layerId, state.selectedAnalysisTool);
                    if (!isValid && layerId === analysisLineLayerId) {
                        state.selectedAnalysisTool = AnalysisType.Distance;
                    } else if (!isValid && layerId === analysisPolygonLayerId) {
                        state.selectedAnalysisTool = AnalysisType.Area;
                    }
                }
            }
            stampValue(state.mapModule.mapObjectsSelected, action.payload.newSelection);
            if (state.mapModule.datesFilter.value.filter) {
                updateMapStateWithFilterChange(state);
            }
        },
        // Pass in a string of map object ids that you want selected. Overwrites current state.
        setCommentsSelected(state: MapGlobalState, action: PayloadAction<SelectedMapComment[]>) {
            stampValue(state.mapModule.commentsSelected, action.payload);
        },
        composeNewObject(state: MapGlobalState, action: PayloadAction<{ objectIds: string[] }>) {
            composeNewObjects(state.mapModule, action.payload.objectIds);
            state.incompleteDrawingObjects.push(...action.payload.objectIds);
        },
        setLocalGeoJson(state: MapGlobalState, action: PayloadAction<CompositionMapObject[]>) {
            setLocalGeoJson(state.mapModule, action.payload);
        },
        setLocalToRemoteMapObjectsKey(state: MapGlobalState, action: PayloadAction<{ value: string; key: string }[]>) {
            setLocalToRemoteMapObjectIds(state.mapModule, action.payload);
        },
        updatePosition(state: MapGlobalState, action: PayloadAction<{ position: StoredPosition; bounds: StoredBounds }>) {
            stampValue(state.mapModule.position, action.payload.position);
            stampValue(state.mapModule.bounds, action.payload.bounds);
        },
        addLayerLocally(state: MapGlobalState, action: PayloadAction<DomainLayer>) {
            stampValue(state.mapModule.layers, [...state.mapModule.layers.value, action.payload]);
        },
        patchMapObjectDataFieldValues(state: MapGlobalState, action: PayloadAction<MapObjectUpdateDataField[]>) {
            action.payload.forEach((update) => {
                const layersObjects = state.mapModule.geoJSON.value[update.layerId];
                layersObjects.forEach((object) => {
                    if (object.objectId === update.object.mapObjectId) {
                        object.feature.properties = {
                            ...object.feature.properties,
                            ...update.object.dataFieldValues,
                        };
                    }
                });
            });
            stampValue(state.mapModule.geoJSON, state.mapModule.geoJSON.value);
        },
        removeLayers(state: MapGlobalState, action: PayloadAction<string[]>) {
            stampValue(
                state.mapModule.layers,
                state.mapModule.layers.value.filter((l) => !action.payload.includes(l.id))
            );
            action.payload.forEach((layerId) => {
                delete state.mapModule.geoJSON.value[layerId];
            });
            stampValue(state.mapModule.geoJSON, state.mapModule.geoJSON.value);
        },
        setBasemap(state: MapGlobalState, action: PayloadAction<MapboxglStyle>) {
            const value = {
                ...state.mapModule.engineSpecificData.styles.value,
                [action.payload.type]: action.payload.style,
            };
            stampValue(state.mapModule.engineSpecificData.styles, value);
        },
        updateMapLayer(state: MapGlobalState, action: PayloadAction<MapModuleLayer>) {
            const layer = action.payload;
            updateMapLayerWithStamp(state, layer);
        },
        updateMapLayers(state: MapGlobalState, action: PayloadAction<MapModuleLayer[]>) {
            action.payload.forEach((layer) => {
                updateMapLayerWithStamp(state, layer);
            });
        },
        updateWaitingFor(state: MapGlobalState, action: PayloadAction<{ waiting: boolean; event: WaitingForEvent }>) {
            if (action.payload.waiting) {
                state.waitingFor.push(action.payload.event);
            } else {
                state.waitingFor = removeWaitingForById(action.payload.event.id, state.waitingFor);
            }
        },
        updateSelectedLevel(state: MapGlobalState, action: PayloadAction<number>) {
            state.selectedBasemap.level = action.payload;
            state.mapModule.currentLevel = action.payload;
            if (state.mapModule.datesFilter.value.filter) {
                updateMapStateWithFilterChange(state);
            }
        },
        updateLayerSelection(state: MapGlobalState, action: PayloadAction<{ selection: string[] }>) {
            const { layers, changed } = updateLayerSelectedValue(state, action.payload.selection);
            if (changed) {
                stampValue(state.mapModule.layers, layers); // Update the layers selected property with a timestamp.
            }
        },
        updateInformationToggle(state: MapGlobalState, action: PayloadAction<boolean>) {
            state.showMapObjectInformation = action.payload;
        },
        setTerrain3D(state: MapGlobalState, action: PayloadAction<boolean>) {
            set3DTerrian(state, action.payload);
        },
        setGlobe(state: MapGlobalState, action: PayloadAction<boolean>) {
            setGlobe(state, action.payload);
        },
        setBuildings3D(state: MapGlobalState, action: PayloadAction<boolean>) {
            stampValue(state.mapModule.buildings3D, action.payload);
        },
        resized(state: MapGlobalState) {
            state.mapModule.onResize = uuid();
        },
        updateSitemapsInView(state: MapGlobalState, action?: PayloadAction<Sitemap[]>) {
            state.sitemapsInView = getSitemapsInView(state.mapModule.bounds.value.bounds, action?.payload ?? state.basemaps.SiteMap.value);
            state.sitemapsInView.forEach((siv) => {
                if (!state.sitemapConfigs.some((sc) => sc.sitemapId === siv.id)) {
                    state.sitemapConfigs.push({
                        sitemapId: siv.id,
                        versionId: siv.versions[siv.versions.length - 1].id,
                        visible: true,
                    });
                }
            });
            // Get newly available levels and update selection
            state.levels = getAvailableLevels(state.sitemapsInView, state.sitemapConfigs);
            const newLevel = getNewLevel(state.levels, state.selectedBasemap.level);
            state.selectedBasemap.level = newLevel;
            state.mapModule.currentLevel = newLevel;
        },
        setMapView(state: MapGlobalState, action: PayloadAction<string>) {
            setMapView(state, action.payload);
        },
        addObjectChangesSaving(state: MapGlobalState, action: PayloadAction<string[]>) {
            const additionalObjectIds = action.payload;
            state.objectsChangesSaving = state.objectsChangesSaving.filter((existingObjectId) => !additionalObjectIds.includes(existingObjectId)).concat(additionalObjectIds);
        },
        removeObjectChangesSaving(state: MapGlobalState, action: PayloadAction<string[]>) {
            const removeObjectIds = action.payload;
            state.objectsChangesSaving = state.objectsChangesSaving.filter((objectId) => !removeObjectIds.includes(objectId));
        },
        removeObjects(state: MapGlobalState, action: PayloadAction<string[]>) {
            removeObjects(state, action.payload);
        },
        addSystemLayer(state: MapGlobalState, action: PayloadAction<MapModuleLayer>) {
            const { layers } = state.mapModule;
            // Only want one system layer type in the map at once
            if (layers.value.some(({ id }) => id === action.payload.id)) {
                throw new Error(`There is already a system layer of id ${action.payload.id} in the map`);
            } else {
                stampValue(state.mapModule.layers, [...state.mapModule.layers.value, action.payload]);
            }
        },
        setMappingEngine(state: MapGlobalState, action: PayloadAction<MappingEngine>) {
            state.mappingEngine = action.payload;
        },
        setUnitOfMeasurement(state: MapGlobalState, action: PayloadAction<UnitOfMeasurement>) {
            stampValue(state.mapModule.unitOfMeasurement, action.payload);
        },
        setBounds(state: MapGlobalState, action: PayloadAction<number[][][]>) {
            stampValue(state.mapModule.bounds, { bounds: action.payload, source: Source.HOST });
        },
        setDrawingToolTip(state: MapGlobalState, action: PayloadAction<MapGlobalState["drawingToolTip"]>) {
            state.drawingToolTip = action.payload;
        },
        setSavedMapViewTooltip(state: MapGlobalState, action: PayloadAction<boolean>) {
            state.savedMapTooltip = action.payload;
        },
        setIsMapPanningAfterSearch(state: MapGlobalState, action: PayloadAction<boolean>) {
            state.isMapPanningAfterSearch = action.payload;
        },
        setHideComments(state: MapGlobalState, action: PayloadAction<boolean>) {
            state.mapModule.layers.value.forEach((layer) => {
                if (layer.id.includes(commentsLayerId)) {
                    layer.visible = !action.payload;
                }
            });
            stampValue(state.mapModule.layers, [...state.mapModule.layers.value]);
            state.mapModule.hideComments = action.payload;
        },
        updateLayerSidebarOrder(state: MapGlobalState, action: PayloadAction<SidebarOrderUpdate[]>) {
            action.payload.forEach(({ id, sidebarOrder }) => {
                const layer = state.mapModule.layers.value.find((l) => l.id === id);
                layer.sidebarOrder = sidebarOrder;
            });
            stampValue(state.mapModule.layers, [...state.mapModule.layers.value]);
        },
        updateLayerGroupIds(state: MapGlobalState, action: PayloadAction<{ layerId: string; groupId?: string }[]>) {
            action.payload?.forEach(({ layerId, groupId }) => {
                const layer = state.mapModule.layers.value.find((l) => l.id === layerId);
                if (layer != null) {
                    layer.groupId = groupId;
                }
            });
            stampValue(state.mapModule.layers, [...state.mapModule.layers.value]);
        },
        // Softly because we do not overwrite existing geometry
        softlySetLayerGeometry(state: MapGlobalState, action: PayloadAction<LocalGeoJsonObject[]>) {
            const formattedMapObjects: CompositionMapObject[] = action.payload.map((object) => ({
                geojson: object.feature,
                layerId: object.feature.properties.layerid,
                objectId: object.objectId,
                waypoints: object.waypoints,
            }));
            setLocalGeoJson(state.mapModule, formattedMapObjects, false);
            setLocalToRemoteMapObjectIds(
                state.mapModule,
                formattedMapObjects.map(({ objectId }) => ({ key: objectId, value: objectId }))
            );
            if (state.mapModule.datesFilter.value.filter) {
                updateMapStateWithFilterChange(state);
            }
        },
        setShowRoutePlanner(state: MapGlobalState, action: PayloadAction<boolean>) {
            state.showRoutePlanner = action.payload;
        },
        updateSelectedCommentPosition(state, action: PayloadAction<[number, number]>) {
            stampValue(state.mapModule.commentsSelected, [{ ...state.mapModule.commentsSelected.value[0], canvasCoordinates: action.payload }]);
        },
        setCommentsDrawingMode(state, action: PayloadAction<CommentsDrawingModes | undefined>) {
            state.mapModule.commentsDrawingMode = action.payload;
        },
        replaceLayers(state, action: PayloadAction<{ layers: MapLayer[] }>) {
            // Readd the comments and analysis layers
            const newLayers = [...action.payload.layers.map((l) => parseLayerToIventisLayer(l)), ...state.mapModule.layers.value.filter((l) => !l.remote)];
            stampValue(state.mapModule.layers, newLayers);
            const geojson = state.mapModule.geoJSON.value;
            Object.keys(geojson).forEach((layerId) => {
                if (!newLayers.some((l) => l.id === layerId)) {
                    delete geojson[layerId];
                }
            });
            stampValue(state.mapModule.geoJSON, geojson);
            if (state.mapModule.datesFilter.value.filter) {
                updateMapStateWithFilterChange(state);
            }
        },
        toggleDateFilter(state, action: PayloadAction<boolean>) {
            const updatedFilter = { ...state.mapModule.datesFilter.value, filter: action.payload };

            // If the day filter is null then set the value to now
            if (action.payload && updatedFilter.day == null) {
                updatedFilter.day = parseDateToEpochSeconds(new Date());
            }

            // If the time filter is null then set the value to now
            if (action.payload && updatedFilter.time == null) {
                updatedFilter.time = parseTimeDateToNumber(new Date());
            }
            updateMapStateWithFilterChange(state, updatedFilter);
        },
        setDateFilterDay(state, action: PayloadAction<Date>) {
            const day = parseDateToEpochSeconds(action.payload);
            const updatedFilter = { ...state.mapModule.datesFilter.value, day };
            updateMapStateWithFilterChange(state, updatedFilter);
        },
        setDateFilterTime(state, action: PayloadAction<Date>) {
            const time = parseTimeDateToNumber(action.payload);
            const updatedFilter = { ...state.mapModule.datesFilter.value, time };
            updateMapStateWithFilterChange(state, updatedFilter);
        },
        setDateFilter(state, action: PayloadAction<{ time: number; day: number }>) {
            updateMapStateWithFilterChange(state, { filter: true, time: action.payload.time, day: action.payload.day });
        },
        disableLayers(state, action: PayloadAction<string[]>) {
            action.payload.forEach((layerId) => {
                const layer = state.mapModule.layers.value.find((l) => l.id === layerId);
                if (layer != null) {
                    layer.disabled = true;
                }
            });
            stampValue(state.mapModule.layers, state.mapModule.layers.value);
        },
        setSelectedAnalysisTool(state, action: PayloadAction<AnalysisType>) {
            state.selectedAnalysisTool = action.payload;
        },
        updateDigitalTwinInstance(state, action: PayloadAction<DigitalTwinInstance>) {
            updateDigitalTwinInstance(state.mapModule, action.payload);
        },
        setDrawingModifier(state, action: PayloadAction<DrawingModifier>) {
            state.mapModule.drawingModifier = action.payload;
        },
        updatePendingGeoJSONForLayer(state, action: PayloadAction<MapState["pendingGeoJSON"]["value"]>) {
            stampValue(state.mapModule.pendingGeoJSON, { ...state.mapModule.pendingGeoJSON.value, ...action.payload });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getGeoJson.pending, (state, action) => {
                const { objects } = action.meta.arg;
                const pendingGeoJSON = state.mapModule.pendingGeoJSON.value;
                objects.forEach(({ objectId, layerId }) => {
                    const pendingLayerGeoJSON = pendingGeoJSON[layerId];
                    // If we're pending all, don't touch the array, since this is handled elsewhere
                    if (pendingLayerGeoJSON === "all") {
                        return;
                    }
                    pendingGeoJSON[layerId] = [...(pendingLayerGeoJSON ?? []), objectId];
                });
                stampValue(state.mapModule.pendingGeoJSON, pendingGeoJSON);
            })
            .addCase(getGeoJson.rejected, (state, action) => {
                const { objects } = action.meta.arg;
                const pendingGeoJSON = state.mapModule.pendingGeoJSON.value;
                objects.forEach(({ objectId, layerId }) => {
                    const pendingLayerGeoJSON = pendingGeoJSON[layerId];
                    // If we're pending all, don't touch the array, since this is handled elsewhere
                    if (pendingLayerGeoJSON === "all") {
                        return;
                    }
                    pendingGeoJSON[layerId] = pendingLayerGeoJSON?.filter((id) => id !== objectId);
                });
                stampValue(state.mapModule.pendingGeoJSON, pendingGeoJSON);
            })
            .addCase(getGeoJson.fulfilled, (state: MapGlobalState, action: PayloadAction<GetGeoJsonResponse[]>) => {
                const formattedMapObjects: CompositionMapObject[] = [];
                const pendingGeoJSON = state.mapModule.pendingGeoJSON.value;

                // Loop through the objects, format them, and update the pendingGeoJSON
                action.payload.forEach((object) => {
                    formattedMapObjects.push({
                        geojson: object.feature,
                        layerId: object.layerId,
                        objectId: object.objectId,
                        waypoints: object.waypoints,
                    });
                    const pendingLayerGeoJSON = pendingGeoJSON[object.layerId];
                    // If we're pending all, don't touch the array, since this is handled elsewhere
                    if (pendingLayerGeoJSON === "all") {
                        return;
                    }
                    pendingGeoJSON[object.layerId] = pendingLayerGeoJSON?.filter((id) => id !== object.objectId);
                });
                setLocalGeoJson(state.mapModule, formattedMapObjects);
                setLocalToRemoteMapObjectIds(
                    state.mapModule,
                    formattedMapObjects.map(({ objectId }) => ({ key: objectId, value: objectId }))
                );
                stampValue(state.mapModule.pendingGeoJSON, pendingGeoJSON);
                if (state.mapModule.datesFilter.value.filter) {
                    updateMapStateWithFilterChange(state);
                }
            })
            .addCase(createObjects.pending, (state, action) => {
                action.meta.arg.forEach((objectUpdate) => {
                    state.creatingObjects.push(objectUpdate.objectId);
                    state.mapModule.localToRemoteMapObjectIdsMap.value[objectUpdate.objectId] = objectUpdate.objectId;
                    // Set both IDs to the same value so we know we've already began creating it
                    setIdPair(state.mapModule, objectUpdate.objectId, objectUpdate.objectId);
                    state.incompleteDrawingObjects = state.incompleteDrawingObjects.filter((object) => object !== objectUpdate.objectId);
                });
            })
            .addCase(createObjects.fulfilled, (state, action) => {
                action.meta.arg.forEach((objectUpdate) => {
                    state.creatingObjects = state.creatingObjects.filter((c) => c !== objectUpdate.objectId);
                });
                // Tell data table objects have been created
                mapDataTableEventStream.next({ type: MapDataTableEvent.MapObjectsCreatedRemotely, payload: action.meta.arg });
            })
            .addCase(createObjects.rejected, (state, action) => {
                action.meta.arg.forEach((objectUpdate) => {
                    state.creatingObjects = state.creatingObjects.filter((c) => c !== objectUpdate.objectId);
                });
            })
            .addCase(deleteObjects.pending, (state: MapGlobalState, action) => {
                const { objectIds, modifyStore } = action.meta.arg;
                if (modifyStore) {
                    // We may choose not to modify the store if we still want to preserve invalid geometries in state for the purposes of allowing undo/ redo while still drawing in the map
                    removeObjects(state, objectIds);
                }
                objectIds.forEach((id) => {
                    // Set the remote ID to undefiend so that creation blocks are triggered in future for updates
                    setIdPair(state.mapModule, id, undefined);
                });
            })
            .addCase(deleteObjects.fulfilled, (state, action) => {
                // Tell data table objects have been deleted
                mapDataTableEventStream.next({ type: MapDataTableEvent.MapObjectsDeletedRemotely, payload: action.meta.arg.objectIds });
            })
            .addCase(deleteObjectsByLayerIds.pending, (state, action) => {
                // Get all objectIds from the given layerIds
                const { layerIds } = action.meta.arg;
                const objectIds = Object.keys(state.mapModule.geoJSON.value).reduce<string[]>((cum, layerId) => {
                    if (layerIds.includes(layerId)) {
                        // If the layerId in the mapModule matches one where we deleted all the map objects, get all the local object ids
                        return [...cum, ...state.mapModule.geoJSON.value[layerId].map(({ objectId }) => objectId)];
                    }
                    return cum;
                }, []);

                removeObjects(state, objectIds);
                objectIds.forEach((id) => {
                    // Set the remote ID to undefiend so that creation blocks are triggered in future for updates
                    setIdPair(state.mapModule, id, undefined);
                });
            })
            .addCase(deleteObjectsByLayerIds.fulfilled, (state, action) => {
                // Get all objectIds from the given layerIds
                const { layerIds } = action.meta.arg;
                const objectIds = Object.keys(state.mapModule.geoJSON.value).reduce<string[]>((cum, layerId) => {
                    if (layerIds.includes(layerId)) {
                        // If the layerId in the mapModule matches one where we deleted all the map objects, get all the local object ids
                        return [...cum, ...state.mapModule.geoJSON.value[layerId].map(({ objectId }) => objectId)];
                    }
                    return cum;
                }, []);
                // Tell data table objects have been deleted
                mapDataTableEventStream.next({ type: MapDataTableEvent.MapObjectsDeletedRemotely, payload: objectIds });
            })
            .addCase(restoreObjects.pending, (state: MapGlobalState, action) => {
                const objectRestorations = action.meta.arg;
                setLocalGeoJson(state.mapModule, objectRestorations.map(mapObjectUpdateToCompositionMapObject));
                objectRestorations.forEach(({ objectId }) => {
                    setIdPair(state.mapModule, objectId, objectId);
                });
            })
            .addCase(restoreObjects.fulfilled, (_, action) => {
                // Tell data table objects have been created
                mapDataTableEventStream.next({ type: MapDataTableEvent.MapObjectsCreatedRemotely, payload: action.meta.arg });
            })
            .addCase(patchMapObjectsDataFieldValues.pending, (state: MapGlobalState, action) => {
                const updatedMapObjects = action.meta.arg.mapObjects;
                const updatedGeoJSON = patchLocalMapObjects(updatedMapObjects, state.mapModule.geoJSON.value);
                stampValue(state.mapModule.geoJSON, updatedGeoJSON);
                if (state.mapModule.datesFilter.value.filter) {
                    updateMapStateWithFilterChange(state);
                }
            })
            .addCase(patchMapObjectsDataFieldValues.fulfilled, (state, action) => {
                const updatedMapObjects = patchImageAttributes(action.payload, state.mapModule.geoJSON.value, state.mapModule.layers.value);
                if (updatedMapObjects != null) {
                    stampValue(state.mapModule.geoJSON, updatedMapObjects);
                }
                if (action.meta.arg.fulfilledNotification) {
                    const getFeatureName = (mapObject: MapObject): string => {
                        const mapObjectNameDataField = getMapObjectNameSystemDatafield(state.mapModule.projectDataFields);
                        return mapObject.dataFieldValues[mapObjectNameDataField.id];
                    };
                    toast.success(
                        {
                            title: translate(Content.common.saved),
                            message: `${translate(Content.common.saved)}  ${
                                action.payload.length === 1 ? getFeatureName(action.payload[0]) : translate(Content.map2.object.multiple_objects)
                            }`,
                            testId: "map-object-saved-toast",
                        },
                        { autoHideDuration: 5000 }
                    );
                }
                eventStream.next({ type: MapEventTypes.REFRESH_LOCAL_OBJECTS });
                if (action.meta.arg.from !== "data-table") {
                    mapDataTableEventStream.next({ type: MapDataTableEvent.MapObjectsUpdatedRemotely });
                }
            })
            .addCase(uploadGeometry.fulfilled, () => {
                mapDataTableEventStream.next({ type: MapDataTableEvent.MapObjectsUpdatedRemotely });
            })
            .addCase(createMapRequest.pending, (state, action) => {
                // While we are pending a response from the mapping service, assign the requestId as the temp id
                state.waitingFor.push({ eventName: SocketEvent.MAP_CREATED, id: action.meta.requestId });
            })
            .addCase(createMapRequest.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<Map, CreateMapRequestPayload>) => {
                const { payload } = action;
                // Updating waitingFor depends on whether the resource is already present in the front end
                const resourceAlreadyInState = state.mapModule.mapId === payload.id;
                // Once we have a successful response, replace the requestId with the resourceId ONLY IF we haven't already received the resource from Comms
                if (resourceAlreadyInState) {
                    state.waitingFor = removeWaitingForById(action.payload.id, state.waitingFor);
                } else {
                    const index = state.waitingFor.findIndex((e) => e.id === action.meta.requestId);
                    state.waitingFor[index] = { ...state.waitingFor[index], id: action.payload.id };
                }
                setMap(state, payload);
            })
            .addCase(createMapRequest.rejected, (state, action) => {
                // Add error state here
                // If the request is rejected, remove from the waitingFor array using the requestId
                state.waitingFor = removeWaitingForById(action.meta.requestId, state.waitingFor);
            })
            .addCase(createLayerRequest.pending, (state, action) => {
                state.waitingFor.push({ eventName: SocketEvent.LAYER_CREATED, id: action.meta.requestId });
            })
            .addCase(createLayerRequest.fulfilled, (state, action: PayloadActionRequests<MapLayer>) => {
                state.waitingFor = removeWaitingForByEventName(SocketEvent.LAYER_CREATED, state.waitingFor);
                stampValue(state.mapModule.layers, [...state.mapModule.layers.value, parseLayerToIventisLayer(action.payload)]);
            })
            .addCase(createLayerRequest.rejected, (state, action) => {
                // Add error state here
                state.waitingFor = removeWaitingForById(action.meta.requestId, state.waitingFor);
            })
            .addCase(getMap.fulfilled, (state, action: PayloadActionRequests<Map>) => {
                setMap(state, action.payload);
            })
            .addCase(getMap.pending, (state, action: PayloadActionRequests<Map>) => {
                state.failedToGetMap = null;
                state.waitingFor.push({ id: action.meta.arg.mapId, eventName: LoadingEvent.GET_MAP });
            })
            .addCase(getMap.rejected, (state, action) => {
                state.waitingFor = removeWaitingForById(LoadingEvent.GET_MAP, state.waitingFor);
                // If the request is aborted then we don't need to throw an error
                if (!action.meta.arg.signal.aborted) {
                    state.failedToGetMap = "default";
                }
            })
            .addCase(getMapModels.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<ModelData[]>) => {
                stampValue(state.mapModule.models, action.payload);
            })
            .addCase(getMapObjectAndCommentBounds.fulfilled, (state, action: PayloadActionRequests<{ mapObjectBounds: Polygon | ""; commentBounds: Polygon | "" }>) => {
                // When there are no map objects on the map bounds returns as an empty string
                if (action.payload.mapObjectBounds !== "") state.mapModule.tileSources.value.objects.bounds = action.payload.mapObjectBounds;
                if (action.payload.commentBounds !== "") state.mapModule.tileSources.value.comments.bounds = action.payload.commentBounds;
                stampValue(state.mapModule.tileSources, state.mapModule.tileSources.value);
            })
            .addCase(getMapObjectsForMap.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<{ geoJSON: LocalGeoJson; finished: boolean }>) => {
                const updatedGeoJSON = mergeGeojson(state.mapModule.geoJSON.value, action.payload.geoJSON);
                stampValue(state.mapModule.geoJSON, updatedGeoJSON);
                setLocalToRemoteMapObjectIds(
                    state.mapModule,
                    Object.values(action.payload.geoJSON).flatMap((objects) =>
                        objects.reduce<
                            {
                                key: string;
                                value: string;
                            }[]
                        >((a, o) => [...a, { key: o.objectId, value: o.objectId }], [])
                    )
                );
                // Set the date filter for all local map objects (which in this case is the models)
                if (state.mapModule.datesFilter.value.filter) {
                    updateMapStateWithFilterChange(state);
                }
            })
            .addCase(patchMapLayer.pending, (state: MapGlobalState, action: PayloadActionRequests<OptionalExceptFor<DomainLayer, "id">>) => {
                patchStateLayer(action.meta.arg, state);
            })
            .addCase(patchMapLayers.pending, (state: MapGlobalState, action: PayloadActionRequests<DomainLayer[]>) => {
                action.meta.arg.forEach(({ id }) => {
                    state.waitingFor.push({ id, eventName: SocketEvent.MAP_LAYER_UPDATED });
                });
            })
            .addCase(patchMapLayers.rejected, (state: MapGlobalState, action) => {
                state.waitingFor = state.waitingFor.filter((e) => !action.meta.arg.some(({ id }) => id === e.id));
                console.error(action.error);
            })
            .addCase(patchMapLayers.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<DomainLayer[]>) => {
                const layers = action.payload;
                layers.forEach((lyr) => patchStateLayer(lyr, state));
                state.waitingFor = state.waitingFor.filter((e) => !action.meta.arg.some(({ id }) => id === e.id));
            })
            .addCase(updateMap.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<{ map: Partial<Map>; skipSave?: boolean }>) => {
                const { map } = action.payload;
                if (map.backgroundId !== null) {
                    state.savedBackgroundDefault = map.backgroundId;
                }
                if (map.sitemapConfigs !== null) {
                    state.sitemapConfigs = map.sitemapConfigs;
                    mapDataTableEventStream.next({ type: MapDataTableEvent.SitemapConfigsUpdated, payload: map.sitemapConfigs });
                }
                state.levels = getAvailableLevels(state.sitemapsInView, state.sitemapConfigs);
                const newLevel = getNewLevel(state.levels, state.selectedBasemap.level);
                state.selectedBasemap.level = newLevel;
                state.mapModule.currentLevel = newLevel;
            })
            .addCase(getBackgroundMaps.pending, (state, action) => {
                state.waitingFor.push({ id: action.meta.requestId, eventName: LoadingEvent.GET_BACKGROUNDS });
            })
            .addCase(getBackgroundMaps.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<Asset[]>) => {
                state.waitingFor = removeWaitingForById(action.meta.requestId, state.waitingFor);
                state.basemaps[AssetType.MapBackground] = action.payload;
                const mapStyles = state.mapModule.engineSpecificData.styles.value;
                if (mapStyles != null) {
                    const updatedStyle = addMetaDataAndTagsToMapBackgroundStyle(action.payload, state.selectedBasemap.MapBackground, mapStyles);
                    if (updatedStyle != null) {
                        stampValue(state.mapModule.engineSpecificData.styles, updatedStyle);
                    }
                }
            })
            .addCase(getBackgroundMaps.rejected, (state, action) => {
                state.waitingFor = removeWaitingForById(action.meta.requestId, state.waitingFor);
            })
            .addCase(getBasemap.pending, (state, action) => {
                // Since getBasemap is called from the getMap response, we must check first if we're not waiting for that initial map to load.
                if (!state.waitingFor.some((e) => e.eventName === LoadingEvent.GET_MAP)) {
                    state.waitingFor.push({ id: action.meta.requestId, eventName: LoadingEvent.GET_BASEMAP });
                }
            })
            .addCase(getBasemap.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<MapboxglStyle>) => {
                state.selectedBasemap[action.payload.type] = action.meta.arg.id;
                // Find the map background asset
                const mapBackgroundAsset = state.basemaps.MapBackground.find((asset) => asset.id === action.meta.arg.id);
                // Add asset metadata and tags to the style metadata so it can be used in the map
                if (typeof action.payload.style.metadata === "object") {
                    action.payload.style.metadata = { ...mapBackgroundAsset?.metaData, ...action.payload.style.metadata, tags: mapBackgroundAsset?.tags };
                }

                const value = {
                    ...state.mapModule.engineSpecificData.styles.value,
                    [action.payload.type]: action.payload.style,
                };
                stampValue(state.mapModule.engineSpecificData.styles, value);

                if (state.waitingFor.some((e) => e.eventName === LoadingEvent.GET_MAP)) {
                    state.waitingFor = removeWaitingForByEventName(LoadingEvent.GET_MAP, state.waitingFor);
                } else {
                    state.waitingFor = removeWaitingForByEventName(LoadingEvent.GET_BASEMAP, state.waitingFor);
                }
            })
            .addCase(getBasemap.rejected, (state) => {
                state.waitingFor = removeWaitingForByEventName(LoadingEvent.GET_BASEMAP, state.waitingFor);
            })
            .addCase(downloadSitemaps.fulfilled, (state: MapGlobalState, action: PayloadAction<{ style: SitemapStyle; asset: Asset }[]>) => {
                // Add the signatures if present for the map tiles
                action.payload.forEach((sitemap) => {
                    if (sitemap.asset.authoritySignature) {
                        // Remove any existing signatures of the same URL
                        state.signatures = state.signatures.filter((s) => s.url !== sitemap.asset.authorityUrl);
                        // Insert new signature
                        state.signatures.push({
                            url: sitemap.asset.authorityUrl,
                            signature: sitemap.asset.authoritySignature,
                            expiry: new Date(new Date().getTime() + sitemap.asset.authoritySignatureExpiry * 1000).toISOString(),
                            assetVersion: sitemap.asset.updatedAt,
                        });
                    }
                });
                // Set the map styles
                const styles = action.payload.map((s) => s.style);
                const value = {
                    [AssetType.MapBackground]: state.mapModule.engineSpecificData.styles.value.MapBackground,
                    [AssetType.SiteMap]: styles,
                };
                stampValue(state.mapModule.engineSpecificData.styles, value);
            })
            .addCase(updateSitemapSignatures.fulfilled, (state, action) => {
                // Add the signatures if present for the map tiles
                action.payload.forEach((asset) => {
                    if (asset.authoritySignature) {
                        // Remove any existing signatures of the same URL
                        state.signatures = state.signatures.filter((s) => s.url !== asset.authorityUrl);
                        // Insert new signature
                        state.signatures.push({
                            url: asset.authorityUrl,
                            signature: asset.authoritySignature,
                            expiry: getExpiryAsISOString(asset.authoritySignatureExpiry),
                            assetVersion: asset.updatedAt,
                        });
                    }
                });
            })
            .addCase(updateLayer.fulfilled, (state, action) => {
                // If text has been switched on for an area style, refresh the source tiles to get centroids
                if (action.payload.styleType === StyleType.Area && getStaticStyleValue(action.payload.areaStyle.text)) {
                    const sourceNames = state.mapModule.tileSources.value.objects.tiles.map((tile) => tile.name);
                    eventStream.next({ type: MapEventTypes.REFRESH_SOURCE_TILES, payload: { sourceNames } });
                }
            })
            .addCase(updateLayer.pending, (state, action) => {
                patchStateLayer(action.meta.arg, state);
            })
            .addCase(updateLayer.rejected, () => {
                toast.error({ title: translate(Content.errors.error), message: translate(content.map4.style.error) });
            })
            .addCase(getProjectDataFields.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<DataField[]>) => {
                state.mapModule.projectDataFields = action.payload;
            })
            .addCase(getSitemaps.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<Sitemap[]>) => {
                stampValue(state.basemaps.SiteMap, action.payload);
                // Select the first version of each basemap
                state.levels = getAvailableLevels(state.sitemapsInView, state.sitemapConfigs);
            })
            .addCase(updateSavedMapView.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<SavedMapView>) => {
                const savedMapViewIndex = state.savedMapViews.findIndex((savedMapView) => savedMapView.id === action.payload?.id);
                if (savedMapViewIndex !== -1) {
                    state.savedMapViews[savedMapViewIndex] = action.payload;
                }
            })
            .addCase(createSavedMapView.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<SavedMapView>) => {
                if (state.savedMapViews !== null) {
                    state.savedMapViews.push(action.payload);
                } else {
                    state.savedMapViews = [action.payload];
                }
            })
            .addCase(patchDefaultSavedMapView.fulfilled, (state: MapGlobalState, action: PayloadActionRequests<string>) => {
                state.savedMapViewDefault = action.payload;
            })
            .addCase(getRemoteLayerGeometry.fulfilled, (state: MapGlobalState, action) => {
                const formattedMapObjects: CompositionMapObject[] = action.payload.map((object) => ({
                    geojson: object.feature,
                    layerId: object.feature.properties.layerid,
                    objectId: object.objectId,
                    waypoints: object.waypoints,
                }));
                setLocalGeoJson(state.mapModule, formattedMapObjects, action.meta.arg.overwrite);
                setLocalToRemoteMapObjectIds(
                    state.mapModule,
                    formattedMapObjects.map(({ objectId }) => ({ key: objectId, value: objectId }))
                );
            })
            .addCase(createLayerDataFieldThunk.pending, (state, action) => {
                const existingDataFields = state.mapModule.layers.value.find((layer) => layer.id === action.meta.arg.layerId)?.dataFields ?? [];
                patchStateLayer({ id: action.meta.arg.layerId, dataFields: [...existingDataFields, action.meta.arg.dataField] }, state);
            })
            .addCase(createLayerDataFieldThunk.rejected, (state, action) => {
                patchStateLayer(
                    {
                        id: action.meta.arg.layerId,
                        dataFields: state.mapModule.layers.value
                            .find((layer) => layer.id === action.meta.arg.layerId)
                            ?.dataFields?.filter((df) => df.id !== action.meta.arg.dataField.id),
                    },
                    state
                );
            })
            .addCase(updateLayerDataFieldThunk.pending, (state, action) => {
                const existingDataFields = state.mapModule.layers.value.find((layer) => layer.id === action.meta.arg.layerId)?.dataFields ?? [];

                const index = existingDataFields.findIndex((df) => df.id === action.meta.arg.dataField.id);

                if (index === -1) {
                    throw new Error(`Cannot find data field: ${action.meta.arg.dataField.name}`);
                }

                const existingDataField = existingDataFields[index];

                // Make sure updated dataField has all of it's properties
                const updatedDataField = { ...existingDataField, ...action.meta.arg.dataField };
                existingDataFields[index] = updatedDataField;
                patchStateLayer({ id: action.meta.arg.layerId, dataFields: existingDataFields }, state);
            })
            .addCase(deleteLayerDataFieldThunk.pending, (state, action) => {
                patchStateLayer(
                    {
                        id: action.meta.arg.layerId,
                        dataFields: state.mapModule.layers.value
                            .find((layer) => layer.id === action.meta.arg.layerId)
                            ?.dataFields?.filter((df) => df.id !== action.meta.arg.dataFieldId),
                    },
                    state
                );
            })
            .addCase(patchMapComment.pending, (state, action) => {
                setLocalGeoJson(state.mapModule, [
                    { geojson: action.meta.arg, objectId: action.meta.arg.properties.id, layerId: action.meta.arg.properties.layerid, waypoints: undefined },
                ]);
            })
            .addCase(getMapCommentFeature.fulfilled, (state, action) => {
                setLocalGeoJson(state.mapModule, [
                    {
                        geojson: action.payload,
                        objectId: action.payload.properties.id,
                        layerId: commentsLayerId,
                        waypoints: undefined,
                    },
                ]);
                if (action.meta.arg.existsOnServer) {
                    setIdPair(state.mapModule, action.payload.properties.id, action.payload.properties.id);
                }
                if (action.meta.arg.focus && action.meta.arg.panTo) {
                    stampValue(state.mapModule.position, {
                        ...state.mapModule.position.value,
                        lng: action.payload.geometry.coordinates[0],
                        lat: action.payload.geometry.coordinates[1],
                        source: Source.HOST,
                        smooth: true,
                    });
                }
            });
    },
});

export {
    stampValue,
    setMode,
    createNewLocalId,
    setIdPair,
    composeNewObjects as composeNewObject,
    setLocalGeoJson,
    updateLayerStyle,
    updateLayerSelectedValue,
    updateDigitalTwinInstance,
};

export const mapReducer = map.reducer;
